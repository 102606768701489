
.product-card {
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    position: relative;
    margin-top: -7px;
    margin-right: -7px;
    border-radius: 3px;
    -webkit-box-align: center;
    align-items: center;
    text-align: center;
    background: white;
    border: 2px solid transparent;
    padding: 7px 7px 20px;
}

.product-card:hover .on-hover{
    display: inherit;
}

.product-card:hover{
    box-shadow: rgb(44 49 68 / 20%) 0px 0px 20px;
}

.hover{
    box-shadow: rgb(44 49 68 / 20%) 0px 0px 20px;
}

.card-image-wrapper {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    position: relative;
    background-color: rgb(243, 243, 243);
    /* padding: 20px 20px 0px; */
    /* margin-right: -30px;
    margin-left: -30px; */
}

.card-image {
    position: relative;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    mix-blend-mode: multiply;
}

.card-action {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: -5px;
    margin-left: -5px;
}

.product-title{
    /* white-space: nowrap; */
    overflow: hidden;
    /* text-overflow: ellipsis; */
    max-width: 100%;
    padding: 0px;
    font-weight: 300 !important;
    font-size: 15px;
    line-height: calc(1em + 10px);
}

.cur {
    cursor: pointer;
}

.placeholder-image{
    min-width: 300;
    min-height: 300;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('./placeholder-image.png');
}