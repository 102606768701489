#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}


.site-layout .site-layout-background {
  background: #fff;
}

.mobile-header {
  display: flex;
  height: 80px;
  z-index: 9999999999999999999999999999999999999999999999999999999999999;
  justify-content: space-between !important;
}

.cs-center {
  max-width: 1444px;
  margin: 0 auto;
}

.site-drawer-render-in-current-wrapper {
  position: relative;
  /* height: 200px; */
  padding: 48px;
  overflow: hidden;
  text-align: center;
  background: #fafafa;
  border: 1px solid #ebedf0;
  border-radius: 2px;
}

.cs-search {
  left: 0 !important;
  top: 84px !important;
}