.no-border {
    background-color: white !important;
    border: none !important;
    box-shadow: none !important
}

.ant-btn{
    padding: 11px 20px !important;
    box-shadow: none !important;
    height: auto;
}

.outline-button {
    color: white !important;
    background-color: transparent !important;
    border: 1px solid white !important;
}

.white-button {
    background-color: white !important;
    color: var(--primary-dark) !important;
    border: none !important;
}

.button-disable{
    opacity: .5;
}

.btn-wp-green{
    padding: 11px 20px !important;
    box-shadow: none !important;
    height: auto;
    background-color: #25D366;
    color: white;
    border: none !important;
    width: 100%;
    margin: 0px 0px 0px 10px;
    position: absolute;
}

.btn-wp-green:hover{
    background-color: #075E54;
    color: white;
}