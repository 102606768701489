.img-cover{
    top:0;
    left: 0;
    position: absolute;
    z-index:0;
}

.blog-card-content{
    z-index: 8;
}

.blog-card{
    background-color: #f5f5f5 !important;
}