.panel-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.h-100 {
    height: 100%;
}

.container {
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    padding-right: 6em;
    padding-left: 6em;
    width: 100%;
    max-width: calc(1400px + 2em);
}

.panel-card {
    background: #fff;
    padding: 2.5rem 2rem 4.5rem;
}

.step-panel{
    margin-top: 4em;
    margin-bottom: 4em;
}
.full-label {
    margin: 0 -2rem 1.5rem;
    width: calc(100% + 4rem);
    padding: 1rem 2rem;
}

.bg-tan {
    background-color: #e3dfd7!important;
}