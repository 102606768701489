.app-header {
  background-color: var(--primary-dark);
  height: 80px !important;
  color: white !important;
}

.header-text {
  margin-left: 14px;

}

.header-item {
  padding-left: 0.5em;
  padding-right: 0.5em;
  cursor: pointer;
}

.header-item-drop {
  margin-top: -4px;
  background-color: #e4fcee;
  color: rgba(0, 0, 0, 0.836) !important;
}

.list-item {
  cursor: pointer;
  padding: 10px 120px 10px 20px;


}

.list-item:hover {
  background-color: #aaceba;
}

.header-content .ant-popover-open,
.header-item:hover,
.header-item-active {
  color: rgba(0, 0, 0, 0.836) !important;
  background-color: #e4fcee;
}

.header-content {
  display: flex;
  align-items: center;
  padding-left: 35px;
  padding-right: 35px;
}

.search-cat {
  /* min-width: 200px; */
  white-space: nowrap;
  cursor: pointer;
  text-align: center;
  height: 40px;
  background-color: #5C7474;
  padding: 7px 20px;
}

.search-cat-mobile {
  /* min-width: 200px; */
  white-space: nowrap;
  cursor: pointer;
  text-align: center;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.103);
  padding: 7px 20px;
}

.center-button .ant-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-button input{
  width: 100%;
  /* padding: 12px 10px 10px 40px !important; */
  border: none !important;
  font-size: 12px !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-top-right-radius: none !important;
}

.center-button .ant-input-affix-wrapper {
  border: 0px !important;
  /* padding: 12px 10px 10px 10px !important; */
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.header-menu{
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
.center-button .ant-input-group-addon {
  display: none;
}
.header-item-first::after{
  content: "";
  width: 1px;
  height: 40px;
  background-color: #5C7474;
  position: absolute;
  right: 0;
}