.footer-ul {
  list-style: none;
}

.footer-li {
  color: grey;
  cursor: pointer;
  margin-top: 10px;
  padding: 0;
}

.footer-li,
.footer-li * {
  color: grey;

}

.footer-li:hover {
  color: var(--primary-dark);
}

.ant-layout-footer {
  padding: 24px 20px !important;
}