.image-preview {
    width: 100px;
    height: 100px;
    max-height: 100px;
    max-width: 100px;
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.233);
    margin-bottom: 10px;
}

.sticky {
    position: -webkit-sticky;
    position: sticky !important;
    top: 70px;
}

table {
    /* table-layout: fixed; */
    width: 200%;
    border-collapse: collapse;
    /* border-spacing: 0 1em !important; */
}

td {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}

th {
    text-align: left;
}

.produc-des-collapse {
    border-top: 1px solid #f5f5f5;
    border-bottom: 1px solid #f5f5f5 !important;
}

.detail-title {
    padding: 0px;
    margin: 0px 0px 1em;
    text-transform: uppercase;
    font-weight: 600 !important;
    letter-spacing: 0.167em;
    font-size: 14px;
    line-height: 1.5;
}

.owl-item-m-20 .owl-item {
    margin: 20px 10px !important;
}

.icon-wrapper-dark {
    background-color: #374646;
    padding: .5em 1em;
    color: white !important;
}

.icon-wrapper-dark-small {
    background-color: #374646;
    padding: .5em .3em;
    color: white !important;
}

.no-scroll-bar::-webkit-scrollbar {
    display: none;
}


.product-name {
    color: inherit;
    /* font-family: 'Playfair Display', 'Times New Roman', serif; */
    font-size: 1.8rem !important;
    line-height: 1.5 !important;
    letter-spacing: normal;
    text-transform: none;
    font-weight: normal !important;
    margin-bottom: 0;
    width: 100%;
}

.more-product {
    border: 1px solid #37464634;
    padding: 1em;
}

@media only screen and (max-width: 600px) {

    .owl-dots {
        display: none !important;
    }

    .image-preview {
        width: 70px;
        height: 70px;
        max-height: 70px;
        max-width: 70px;
    }
}

.cs-des p {
    margin-bottom: 10px;
}