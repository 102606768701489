@font-face {
    font-family: 'Butler';
    src: url('Butler-UltraLight.woff2') format('woff2'),
        url('Butler-UltraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Butler';
    src: url('Butler-Medium.woff2') format('woff2'),
        url('Butler-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Butler';
    src: url('Butler-Light.woff2') format('woff2'),
        url('Butler-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Butler';
    src: url('Butler-Black.woff2') format('woff2'),
        url('Butler-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Butler';
    src: url('Butler-Bold.woff2') format('woff2'),
        url('Butler-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Butler';
    src: url('Butler-ExtraBold.woff2') format('woff2'),
        url('Butler-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Butler';
    src: url('Butler.woff2') format('woff2'),
        url('Butler.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Arce';
    src: url('./glacial-regular.otf') format('otf');
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'Amsterdam';
    src: url('./Amsterdam\ Four_ttf\ 400.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
}