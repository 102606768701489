.total {
    padding: 10px 10px;
    background-color: #fff;
    border-top: 1px solid #4a6161;
}

.top {
    padding: 5px 10px;
    color: #fff;
    background-color: #4a6161;
}

.sub-top {
    padding: 5px 10px;
    background-color: #fff;
    border-top: 1px solid #4a6161;

}

.product {
    padding: 10px 10px;
    background-color: #fff;
    border-top: 1px solid #4a6161;

}