.mega-menu-wrapper {
  background-color: #3f4d4d;
  height: 40px;
}

.menu-ul {
  display: flex;
  text-align: center;
  align-items: center;
  list-style: none;
  height: 100%;
}

.menu-li {
  cursor: pointer;
  padding-top: 10px;
  height: 100%;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
}

.menu-li-clone {
  cursor: pointer;
  padding-top: 10px;
  height: 100%;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
}

.menu-li:hover {
  color: inherit;
  text-decoration: underline;
}

.menu-item-li {
  margin-bottom: 5px;
  cursor: pointer;
}


.menu-item-li:hover {
  /* border-bottom: 1px solid #374646; */
  /* font-weight: 500 !important; */
  color: inherit;
  text-decoration: underline;
}

.mega-menu-wrapper .ant-popover-open,
.menu-li:hover {
  background-color: #e4fcee;
  color: black;
}

.menu-popover {
  margin-top: -4px;
  background-color: #e4fcee;
  padding: 40px;
}

.menu-item-ul {
  list-style: none;
  padding: 0;
}

.vr-line {
  background-color: #394747;
  opacity: .1 !important;
  width: 1px !important;
  height: 100%;
  margin-left: 30px;
  margin-right: 20px;
}


.hr-line {
  background-color: #394747;
  opacity: .1 !important;
  height: 1px !important;
  width: 100%;
  margin-left: 30px;
  margin-right: 20px;
}

.abs-pop {
  left: 40px !important;
  width: 60%;
}



.dis-c {
  display: contents !important;
}

@media only screen and (min-width: 1900px) {
  .abs-pop-hd {
    left: 15% !important;
    width: 60%;
  }

  /* .abs-pop {
    left:   20% !important;
  } */

}

.menu-item-li {
  font-size: 11px;
}