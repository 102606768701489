.mobile-mt {
    margin-top: 90px;
}

.app-title {
    line-height: normal;
    font-size: 42px;
}

.app-sub-title {
    opacity: .8;
    font-size: 18px !important;
}

.app-hero-image {
    width: 100%;
    height: 100%;
}

.first-wrapper {
    position: absolute;
    z-index: 900;
    top: 0;
    left: 0;
    /* width: 40%; */
    background-color: white;
    border-radius: 1em;
    box-shadow: 20px 20px 15px 0 rgb(0 0 0 / 16%);
}

.second-wrapper {
    position: absolute;
    z-index: 900;
    top: 70%;
    right: 0;
    /* width: 40%; */
    background-color: white;
    border-radius: 1em;
    box-shadow: 20px 20px 15px 0 rgb(0 0 0 / 16%);
}

.icon-wrapper-green {
    background-color: #F5FAFA;
    padding: 1px;
    padding-bottom: 10px;
    font-size: 32px;
    border-radius: 50px;
}

.icon-wrapper-green>svg {
    margin-top: -200px !important;
}

.ant-carousel {
    width: 100%;

}

/* ====================================================       ================================================= */

.cat-card-wrapper {
    background-color: #f5f5f5;
    border-radius: 10px;
    min-height: 125px;
    /* width: 200px; */
    /* margin-right: 10px; */
    /* padding-top: 40px !important; */
    /* padding-bottom: 40px !important; */
}

.cat-card-image-wrapper-up {
    margin-top: -40px;
}

.cat-card-image-wrapper-down {
    margin-bottom: -40px !important;
}

.cat-card-text-wrapper-down {
    margin-bottom: -30px !important;
}

.cat-card-text-wrapper-up {
    margin-top: -30px;
}

/* ====================================================       ================================================= */

.stats-card {
    padding: 2em;
    background-color: white;
    margin-bottom: 30px;
    box-shadow: 0px 0px 20px 0 rgb(0 0 0 / 10%);
}

.stats-card>h3 {
    font-size: 42px;
    margin: 0 !important;
}

.primary-back {
    background-color: #d0f3f3 !important;
}

.orange-back {
    background-color: #f3f0d0 !important;
}


/* ====================================================       ================================================= */

.choose-us-image-wrapper {
    /* padding: 0em .5em 0em .5em; */
    background-color: white;
    box-shadow: 0px 0px 20px 0 rgb(0 0 0 / 10%);
    border-radius: 4px;
    margin-bottom: 30px;
    margin-left: 30px;
    height: 10% !important;
}

.choose-us-image-wrapper>img {
    height: 190px !important;
}

.image-section-back {
    background-color: #F5FAFA;
    /* background-color: #DBE6E0; */
}

.mobile-p-90 {
    padding: 90px;
}


@media only screen and (max-width: 600px) {
    .mobile-mt {
        margin-top: 30px;
    }

    .mobile-p-90 {
        padding: 60px;
    }

    .app-title {
        font-size: 32px;
    }

    .app-sub-title {
        font-size: 14px !important;
    }

    .first-wrapper {
        width: 60%;
        margin-top: -50px;
        position: relative;
    }

    .second-wrapper {
        top: 30px;
        width: 60%;
        position: relative;
        margin-bottom: 90px !important;
        /* margin-top: 30px; */
    }

    .main-wrapper {
        padding: 10px 15px !important;
    }

}

.progress_wrapper {
    font-size: 0;
    display: flex;
}

.progress_wrapper .progress_inner_box .upper_section {
    position: relative;
    text-align: center;
}

.progress_wrapper .progress_inner_box .lower_section {
    margin-top: 25px;
    text-align: center;
}

.progress_wrapper .progress_inner_box .cs-line:before {
    content: '';
    position: absolute;
    top: auto;
    bottom: 0;
    left: 50%;
    width: 100%;
    height: 2px;
    background-color: #F5FAFA;
}

.progress_wrapper .progress_inner_box .upper_section .box_name {
    font-weight: bold;
    vertical-align: bottom;
    margin: 0;
    font-size: 18px;
}

.progress_wrapper .progress_inner_box .upper_section .box_icon_wrapper {
    margin-top: 15px;
    position: relative;
    display: inline-block;
    max-width: 40px;
    overflow: visible;
    z-index: 0;
}

.progress_wrapper .progress_inner_box .upper_section .box_icon_wrapper::before {
    content: '';
    position: absolute;
    top: 0;
    left: calc(50% - 22.5px);
    height: 45px;
    border-radius: 50%;
    z-index: -1;
    width: 45px;
    background-color: #F5FAFA;
}

.progress_wrapper .progress_inner_box:first-child {
    margin-left: -2%;
}

.progress_wrapper .progress_inner_box .lower_section .box_text {
    max-width: 90%;
}

.progress_wrapper .progress_inner_box .lower_section .box_text {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {

    .progress_wrapper {
        font-size: 0;
        display: flex;
        flex-direction: column;
    }

    .progress_wrapper .progress_inner_box .cs-line:before {
        display: none;
    }

}

.ant-carousel .slick-dots li button {
    background-color: black !important;
}


.ant-carousel .slick-prev,
.ant-carousel .slick-next {
    color: black !important;
    font-size: 12px !important;
}

.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
    color: unset;
}