.grid{
    display: grid;
    grid-gap: 30px 30px;
    grid-template-columns: repeat(auto-fill,minmax(260px,1fr));
}

.on-hover{
    display: none;
}

@media (max-width: 767px){
    .on-hover{
        display: inherit;
    }
    .grid {
        grid-gap: 21px 14px;
        grid-template-columns: repeat(2,calc(50% - 7px));
    }
}