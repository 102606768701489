@import url("../assets//fonts//stylesheet.css");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;500;600;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@100;200;300;500;600;900&display=swap');
@import url('https://fonts.cdnfonts.com/css/glacial-indifference-2');
/* @import url('http://fonts.cdnfonts.com/css/amsterdam-2'); */

* {
	font-family: 'Glacial Indifference', sans-serif !important;
	font-weight: 300 !important;
}

h1,
h1 *,
h2,
h2 * {
	font-family: Butler !important;
	font-weight: 600 !important;
	color: rgb(63, 68, 88);
}

h3,
h3 *,
h4,
h4 * {
	font-family: 'Glacial Indifference' !important;
	font-weight: 600 !important;
	color: rgb(63, 68, 88);
}

p strong {
	font-weight: 600 !important;
}

.cs-am {
	font-family: 'Amsterdam', sans-serif !important;
	font-weight: 100 !important;
}

.cs-content-title {
	line-height: 32px;
	font-size: 24px;
	color: rgb(63, 68, 88);
}

.p-large {
	line-height: 26px;
	font-size: 18px;
	color: rgb(63, 68, 88);
}

.pre-title {
	font-size: 12px;
	color: rgb(126, 131, 149);
	line-height: 18px;
	font-weight: 600 !important;
	text-transform: uppercase;
	letter-spacing: 0.15em;
}

.p-small {
	line-height: 20px;
	font-size: 14px;
	color: rgb(63, 68, 88);
}

.fnt-40 {
	font-size: 40px;
}

.lh-46 {
	line-height: 46px;
}

.fnt-50 {
	font-size: 50px;
}

.lh-58 {
	line-height: 58px;
}

.lh-0 {
	line-height: 0px;
}

.lh-86 {
	line-height: 86px;
}

.fnt-80 {
	font-size: 80px;
}

.fnt-72 {
	font-size: 72px;
}

.cs-sub-title {
	font-size: 40px;
	line-height: 46px;
}

.fnt-bold {
	font-weight: bold !important;
}

.fnt-medium {
	font-weight: 500 !important;
}

.arc-sub-title {
	font-family: 'Glacial Indifference' !important;
	line-height: 1.5 !important;
	/* font-weight: 300 !important; */
	font-size: 12px !important;
	letter-spacing: 0.025em !important;
}

.arc-title {
	font-family: 'Glacial Indifference' !important;
	line-height: 1.5 !important;
	/* font-weight: 300 !important; */
	font-size: 13px !important;
	text-transform: uppercase;
	letter-spacing: 0.154em !important;
}

.cs-product-title {
	font-family: 'Playfair Display', serif !important;
	font-size: 2rem !important;
	line-height: 1.2 !important;
	font-weight: normal !important;
}

.cs-pl {
	font-family: 'Playfair Display', serif !important;
	font-weight: 100 !important;

}

.cs-product-sub-title,
.cs-product-sub-title *,
.cs-product-sub-title>p {
	/* font-family: 'Glacial Indifference', serif !important; */
	font-size: 14x !important;
	line-height: 20px !important;
	font-weight: 100 !important;
}

.cs-product-sub-title-me {
	/* font-family: 'Glacial Indifference', serif !important; */
	font-size: 17px !important;
	line-height: 22px !important;
	font-weight: 600 !important;
}

.txt-logo {
	color: #fff !important;
	font-size: 24px;
	line-height: 28px;
	margin-top: 12px;
	cursor: pointer;
}

.header-menu .ant-menu-title-content {
	/* font-weight: 100 !important; */
	/* font-size: 13px !important; */
	/* line-height: 1.73 !important; */
	/* letter-spacing: 1.5px !important; */
	/* text-transform: uppercase !important; */
	/* color: black !important; */
}

.header-menu .ant-menu-submenu-title,
.header-menu .ant-menu-title-content,
.header-menu .ant-menu-item,
.header-menu .ant-menu-item-selected,
.header-menu .ant-menu-item-only-child {
	padding-left: 10px !important;
}

.header-menu .ant-menu-item-selected>.ant-menu-title-content {
	border: 1px solid #c5c5c5 !important;
	padding: 9px;
	background-color: #2a3636;
	color: #fff !important;
	border-radius: 7px;
}

.header-menu .ant-menu-item {
	/* margin: 5px !important; */
	height: 100% !important;
	padding: 10px 0px;
}

.header-menu .ant-menu-item>.ant-menu-title-content {
	border: 1px solid #c5c5c5 !important;
	padding: 9px;
	border-radius: 7px;
}

.header-menu>li>ul {
	background-color: #fff !important;
	box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%) !important;
}

.header-menu .ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
	display: none;
}

.acre-12-1-R {
	color: inherit;
	font-size: 0.92307692rem;
	line-height: 1.73;
	letter-spacing: 1px;
}

.ant-menu-inline.ant-menu-root .ant-menu-item,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
	display: inherit;
	list-style: none !important;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {

	.cs-content-title {
		line-height: 36px;
		font-size: 20px;
	}

	.p-large {
		line-height: 20px;
		font-size: 14px;
	}

	.pre-title {
		font-size: 10px;
		line-height: 16px;
	}

	.p-small {
		line-height: 16px;
		font-size: 10px;
	}

	.fnt-40 {
		font-size: 36px;
	}

	.lh-46 {
		line-height: 42px;
	}

	.fnt-50 {
		font-size: 42px;
	}

	.lh-58 {
		line-height: 48px;
	}


	.lh-86 {
		line-height: 62px;
	}

	.fnt-80 {
		font-size: 68px;
	}

	.fnt-72 {
		font-size: 62px;
	}

	.cs-sub-title {
		font-size: 32px;
		line-height: 36px;
	}

	.arc-sub-title {
		font-size: 10px !important;
	}

	.arc-title {
		font-size: 11px !important;
	}

	.cs-product-title {
		font-size: 1.5rem !important;
	}

	.cs-product-sub-title,
	.cs-product-sub-title *,
	.cs-product-sub-title>p {
		font-size: 12px !important;
		line-height: 18px !important;
	}

	.cs-product-sub-title-me {
		/* font-family: 'Glacial Indifference', serif !important; */
		font-size: 13px !important;
		line-height: 19px !important;
	}

	.txt-logo {
		font-size: 20px;
		line-height: 26px;
	}

	.header-menu .ant-menu-title-content {
		font-size: 10px !important;
		line-height: 1.3 !important;
	}

	.product-title {
		font-size: 13px;
		line-height: 18px;
	}
}


.fnt-grey {
	color: #474747 !important;
	font-weight: 300 !important;
	font-family: Butler !important;
}