.m-0{
    margin: 0 !important;
}

.m-5{
    margin: 5px;
}

.m-point5 {
    margin: .5em;
}

 /* ==============================================  Top  =====================================================*/

.mt-10 {
    margin-top: 10px;
}

.mt-5 {
    margin-top: 5px;
}

.mt-15{
    margin-top: 15px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-25{
    margin-top: 25px;
}

.mt-30 {
    margin-top: 30px;
}

 /* ==============================================  Left  =====================================================*/

.ml-5{
    margin-left: 5px;
}

.ml-10 {
    margin-left: 10px;
}

.ml-20 {
    margin-left: 10px;
}

.ml-30 {
    margin-left: 30px;
}

 /* ==============================================  right  =====================================================*/

.mr-5{
    margin-right: 5px;
}

.mr-10{
    margin-right: 10px;
}

.mr-20 {
    margin-right: 20px;
}

.mr-30{
    margin-right: 30px;
}


 /* ==============================================  bottom  =====================================================*/

.mb-20 {
    margin-bottom: 20px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-5 {
    margin-bottom: 5px;
}

.mb-30 {
    margin-bottom: 30px;
}