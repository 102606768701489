@media (max-width:768px) {
    .article-wrap {
        margin-bottom: 40px
    }
}



.bg-cover {
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat
}



.caps {
    text-transform: uppercase
}

.headline-1,
h1 {
    font-family: GT, serif;
    font-size: 47px;
    letter-spacing: 0;
    line-height: 49px
}

@media (max-width:768px) {

    .headline-1,
    h1 {
        font-size: 40px;
        letter-spacing: 0;
        line-height: 41px
    }
}

.opc:hover {
    opacity: .6;
    text-decoration: none
}



.w-50 {
    width: 50% !important;
}

.bg-cover {
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
}

.h-100 {
    height: 100% !important;
}

.hero-content {
    padding-top: 135px;
    padding-bottom: 135px;
}

.w-430 {
    width: 430px;
    max-width: 100%;
}

.margin-auto {
    margin-left: auto;
    margin-right: auto;
}

.hero-content {
    padding-top: 135px;
    padding-bottom: 135px;
}

.bg-tan-1 {
    background-color: #f8f7f3;
}

.sec-headline {
    padding-top: 24px;
    padding-bottom: 20px;
    border-bottom: 1px solid hsla(0, 0%, 59%, .5);
    margin-bottom: 30px;
}

.headline-3 {
    font-family: forma-djr-text, sans-serif;
    font-size: 17px;
    color: #737373;
    letter-spacing: 3.11px;
    line-height: 17px;
}

.align-center {
    text-align: center;
}

.border-top-norm {
    border-top: 1px solid hsla(0, 0%, 59%, .5);
}

.read-more {
    margin-left: 5px;
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

.grid-item,
.grid-sizer {
    width: 25%;
}

.grid-item,
.grid-sizer {
    width: 25%;
}

.grid-item-gap {
    padding: 15px;
}

.grid-item--width2 {
    width: 50%;
}

.grid-item-inner {
    border-bottom: 1px solid hsla(0, 0%, 59%, .5);
    padding-bottom: 15px;
}

.w-430 {
    width: 430px;
    max-width: 100%;
}

.grid-item-gap {
    padding: 15px;
}

.grid-item-img {
    width: 100%;
}

@media (max-width: 768px) {

    .grid-item,
    .grid-sizer {
        width: 50%;
    }

    .grid-item-gap {
        padding-top: 0 !important;
    }
}

@keyframes comeIn {
    0% {
        transform: scale(0)
    }

    75% {
        transform: scale(1.03)
    }

    100% {
        transform: scale(1)
    }
}

.xmasonry .xblock {
    animation: comeIn ease 0.5s;
    animation-iteration-count: 1;
    transition: left .3s ease, top .3s ease;
}

.xmasonry-static {
    text-align: center;
    overflow: auto;
}

.xblock-static {
    float: left;
    text-align: left;
}