 /* ==============================================  CONTENT  =====================================================*/

/* 1 Font Size */
/* 2 Width */


/* ==============================================  FONT - SIZE  =====================================================*/
.fnt-sz-11{
    font-size: 11px;
}

.fnt-sz-12{
    font-size: 12px;
}

.fnt-sz-16{
    font-size: 16px;
}

.fnt-sz-20{
    font-size: 20px;
}

.fnt-sz-18{
    font-size: 18px;
}

.fnt-sz-24{
    font-size: 24px;
}

.fnt-sz-32{
    font-size: 32px;
}

 /* ==============================================  WIDTH  =====================================================*/

.w-30{
    width: 30%;
}

.w-60{
    width: 60%;
}

.w-70{
    width: 70%;
}

.w-80 {
    width: 80%;
}

.w-90{
    width: 90%;
}

.w-95 {
    width: 95%;
}

.w-100 {
    width: 100%;
}

.wh-30{
    width: 40px;
    height: 40px;
}

.wh-80px{
    width: 80px;
    height: 80px;
}

.wh-50px{
    width: 50px;
    height: 50px;
}

.wh-60px{
    width: 60px;
    height: 60px;
}

.wh-100{
    width: 100%;
    max-height: 500px;
    /* height: 600p; */
}