.app-article {
  background: white;
}
.app-article .app-article-richtext {
  margin: 20px auto;
}
.app-article .app-article-title {
  margin-top: 10px;
}
.app-article .app-article-quote {
  background: #e5ded2;
  border-left: 0;
  margin: -60px 0;
  padding: 100px 0 110px;
  text-align: center;
}
.app-article .app-article-quote blockquote {
  display: block;
  border: 0;
  color: #2c3144;
  max-width: 660px;
  margin: 0 auto;
  text-align: center;
}
.app-article .app-article-header-image,
.app-article .app-article-landscape-image {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.app-article .app-article-header-image img,
.app-article .app-article-landscape-image img {
  display: block;
  height: auto;
  object-fit: cover;
  object-position: center;
  margin: 0 auto;
  max-height: 500px;
  width: 100%;
}
.app-article .app-article-header-image,
.app-article .app-article-image-collapse {
  margin-bottom: -60px;
}
.app-article .app-article-image-caption {
  color: #bfc1ca;
  padding-top: 10px;
}
.app-article .app-article-double-image-caption {
  color: #bfc1ca;
  margin-top: -10px;
}
.app-article .app-quote-centered,
.app-article .app-article-challenge-centered,
.app-article .app-article-image-caption-centered,
.app-article .app-article-centered {
  margin: 0 auto;
  max-width: 660px;
  padding-left: 80px;
  padding-right: 80px;
}
.app-article .app-article-image-caption-centered,
.app-article .app-article-centered {
  background: white;
}
.app-article .app-article-centered {
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
}
.app-article .app-article-centered + .app-article-image-collapse {
  margin-top: -60px;
}
.app-article .app-article-centered.app-article-richtext {
  padding-bottom: 40px;
  padding-top: 40px;
}
.app-article .app-article-header-image + .app-article-centered,
.app-article .app-article-landscape-image + .app-article-centered,
.app-article .app-article-quote + .app-article-centered {
  padding-top: 40px;
}
.app-article .app-article-header + .app-article-richtext,
.app-article .app-article-heading + .app-article-richtext,
.app-article .app-article-richtext + .app-article-richtext {
  padding-top: 0;
}
.app-article .app-article-richtext + .app-article-richtext {
  margin-top: -40px;
}
.app-article .app-article-embed,
.app-article .app-article-block-double-image,
.app-article .app-article-block-image {
  margin: 0 auto;
  max-width: 660px;
  padding: 20px 0;
}
.app-article .app-article-block-image img {
  display: block;
  height: auto;
  width: auto;
  margin: 0 auto;
  max-width: 100%;
}
.app-article .app-article-embed iframe {
  display: block;
  margin: 0 auto;
  max-width: calc(100vw);
  max-height: calc(60vw);
}
.app-article .app-article-block-double-image {
  display: flex;
  flex-wrap: wrap;
}
.app-article .app-article-block-double-left,
.app-article .app-article-block-double-right {
  flex: 1;
}
.app-article .app-article-block-double-left img,
.app-article .app-article-block-double-right img {
  display: block;
}
.app-article .app-article-block-double-left img {
  margin: 0 20px 0 0;
}
.app-article .app-article-block-double-right img,
.app-article .app-article-block-double-right .app-article-image-caption {
  margin: 0 0 0 20px;
}
@media all and (max-width: 726px) {
  .app-article .app-quote-centered,
  .app-article .app-article-image-caption-centered,
  .app-article .app-article-challenge-centered,
  .app-article .app-article-centered {
    padding-left: 10%;
    padding-right: 10%;
  }
  .app-article .app-article-richtext {
    word-break: break-word;
  }
  .app-article .app-article-block-double-left,
  .app-article .app-article-block-double-right {
    margin-bottom: 10px;
  }
  .app-article .app-article-block-double-left img,
  .app-article .app-article-block-double-right img {
    margin: 0 auto;
  }
}
.app-article-author {
  border-bottom: 1px solid #f2f3f4;
  border-top: 1px solid #f2f3f4;
  margin: 20px 0 0;
  padding: 10px 0;
}
.app-article-author .app-article-author-icon,
.app-article-author .app-article-author-image {
  display: inline-block;
  border-radius: 30px;
  height: 60px;
  width: 60px;
  margin-right: 10px;
  object-fit: cover;
}
.app-article-author .app-article-author-icon {
  align-items: center;
  background: #2c3144;
  color: white;
  display: inline-flex;
  font-size: 38px;
  justify-content: center;
  vertical-align: middle;
  width: 60px;
}
.app-quote-icon {
  line-height: 20px;
  font-size: 100px;
  padding: 30px 0 10px 0;
}
.app-article-siblings {
  margin: 20px auto;
  max-width: 1090px;
  padding: 0 20px;
}
.app-article-siblings .app-article-siblings-title {
  margin: 20px auto;
  text-align: center;
}
.app-article-centered-footer {
  border-top: 1px solid #f2f3f4;
}
.app-article-centered-footer .app-article-tags-hld {
  align-items: center;
  display: flex;
  margin: 20px 10px;
  padding: 10px 0 20px;
}
.app-article-centered-footer .app-article-tags-title {
  padding-right: 20px;
  white-space: nowrap;
}
.app-article-centered-footer .app-article-tags {
  display: flex;
  flex-wrap: wrap;
}
.app-article-centered-footer .app-article-tag {
  color: inherit;
  margin: 10px 10px 10px 0;
}
.app-article-footer {
  margin: 0 auto;
  max-width: 1090px;
  padding: 0 20px;
}
.app-article-quote + .app-article-centered .app-article-centered-footer,
.app-article-landscape-image + .app-article-centered .app-article-centered-footer {
  border-top: none;
}
.app-article-product-carousel {
  background: #e5ded2;
  margin: -60px 0;
  padding: 100px 0 100px;
}

.app-article-challenge {
  background: #e5ded2;
  border-left: 0;
  margin: -60px 0;
  padding: 100px 0 110px;
  text-align: left;
}
.app-article-challenge .app-article-challenge-submit {
  margin: 20px auto;
}
.app-article-challenge .app-article-challenge-opt {
  display: flex;
}
.app-article-challenge .app-article-challenge-check {
  margin-left: -14px;
}

.app-article-image-gallery {
  margin-bottom: -60px;
  margin-top: 90px;
}
.app-article-image-slider-container {
  margin: 0 auto;
}
.app-article-image-slider .slick-track {
  display: flex;
}
.app-article-image-slider .app-carousel-next,
.app-article-image-slider .app-carousel-prev {
  z-index: 1;
  position: static;
  transform: initial;
}
.app-article-image-slider .app-carousel-next .app-icon-border,
.app-article-image-slider .app-carousel-prev .app-icon-border {
  background: white;
}
.app-article-image-slider .app-carousel-prev {
  margin-right: 30px;
}
.app-article-image-slide-content {
  height: 100%;
  width: 100%;
}
.app-article-image-slide {
  position: relative;
  display: flex;
  height: auto;
  flex-direction: column;
}
.app-article-image-slide .app-aig-top-section {
  background: white;
  max-width: 1050px;
  margin: 0 auto;
  width: 100%;
  min-height: 280px;
  padding: 0 40px;
  position: relative;
}
.app-article-image-slide .app-aig-image-section > div,
.app-article-image-slide .app-article-image-slider-arrows > div {
  max-width: 1050px;
  margin: 0 auto;
}
.app-article-image-slide .app-aig-image-section > div {
  padding: 0 40px;
}
.app-article-image-slide .app-article-image-slider-arrows {
  bottom: 100px;
  left: 0;
  position: absolute;
  right: 0;
}
.app-article-image-slide .app-aig-image-section {
  background: #e5ded2;
  padding: 30px 0 100px;
  flex: 1;
  width: 100%;
  min-height: 380px;
}
.app-article-image-slide .app-aig-image-margin {
  margin-right: 520px;
}
.app-article-image-slide .app-aig-image {
  position: absolute;
  right: 40px;
  top: 0;
}
.app-article-image-slide .app-aig-image img {
  margin-left: auto;
}
.app-article-image-slide .app-aig-section-title {
  margin-bottom: 10px;
}
.app-article-image-slide .app-aig-trend-subtitle {
  margin-top: 10px;
}
.app-article-image-slide .app-article-author {
  margin-top: 30px;
  margin-bottom: 30px;
}
.app-article-image-slide .app-aig-gallery-title {
  margin-top: 30px;
  margin-bottom: 20px;
}
.app-article-image-slide .app-aig-gallery-label {
  margin-top: 5px;
  padding-bottom: 65px;
}
.app-article-image-slide .app-aig-gallery-label p:last-child {
  margin-bottom: 0;
}
.app-article-image-slider-mobile .app-article-image-slide .app-aig-top-section,
.app-article-image-slider-mobile .app-article-image-slide .app-aig-image-section > div {
  padding: 0 20px;
  max-width: 600px;
  min-height: initial;
}
.app-article-image-slider-mobile .app-article-image-slide .app-aig-image-margin {
  margin-right: initial;
}
.app-article-image-slider-mobile .app-article-image-slide .app-aig-section-title {
  margin-bottom: 10px;
}
.app-article-image-slider-mobile .app-article-image-slide .app-aig-gallery-title {
  margin-top: 0;
}
.app-article-image-slider-mobile .app-article-image-slide .app-aig-label-section {
  margin-top: 30px;
}
.app-article-image-slider-mobile .app-article-image-slide .app-aig-image {
  position: static;
  margin: 30px 0;
}
.app-article-image-slider-mobile .app-article-image-slide .app-aig-image img {
  max-width: 100%;
  height: auto;
  margin-left: 0;
}
.app-article-image-slider-mobile .app-article-image-slide .app-aig-gallery-label {
  padding-bottom: 0;
}
.app-article-image-slider-mobile .app-article-image-slide .app-article-image-slider-arrows {
  position: static;
}
.app-article-image-slider-mobile .app-article-image-slide .app-carousel-prev {
  margin-right: 40px;
}

.app-content-wrapper {
  background: white;
}
.app-article-journal-home,
.app-article-tag-index,
.app-article-index {
  margin: 0 auto;
  max-width: 1090px;
  padding: 0 20px;
}
.app-article-journal-title,
.app-article-index-title {
  margin-top: 50px;
  margin-bottom: 10px;
}
.app-article-tag-index-title {
  margin-top: 20px;
  color: #7e8395;
}
.app-article-tag-name {
  text-transform: capitalize;
  margin-bottom: 10px;
}
.app-article-index-intro {
  margin: 10px 0 60px;
}
.app-article-index-intro p {
  padding: 0;
}
.app-article-index-item .app-article-index-item-thumb {
  max-width: calc(100vw - 10px);
  margin: 0 auto;
}
.app-article-index-item .app-article-index-item-thumb img {
  display: block;
  height: auto;
  max-width: 100%;
  margin: 0 auto;
}
.app-article-index-item .app-article-index-item-type {
  padding: 10px 10px 0;
}
.app-article-index-item .app-article-index-item-title {
  display: block;
  color: #7e8395;
  padding: 5px 10px 0;
}
.app-article-section {
  margin: 20px 0 40px 0;
}
.app-article-section-info {
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 20px;
}
.app-article-tag-index .app-article-section-info {
  margin-bottom: 50px;
}
.app-article-tag-index .app-article-section-count-hld {
  align-self: flex-end;
}
.app-article-section-count-hld {
  margin-left: auto;
}
.app-article-section-count {
  color: #7e8395;
}
.app-article-section-link {
  margin: 0 0 0 30px;
}
.app-article-index-info-section {
  border-top: 1px solid #f2f3f4;
  border-bottom: 1px solid #f2f3f4;
  padding: 40px 0;
}
.app-article-section-title {
  margin-bottom: 10px;
}

.app-article-list-items {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
.app-article-list-items .app-article-index-item {
  margin: 20px 10px;
}
@supports (display: grid) {
  .app-article-list-items {
    display: grid;
    grid-template-columns: repeat(auto-fill, 330px);
    grid-gap: 30px;
    justify-content: center;
    margin: 0 auto;
    max-width: 100%;
    transform: translate3d(0, 0, 0);
  }
  .app-article-list-items .app-article-index-item {
    margin: 0;
  }
}

